:root {
  --background-color: #efefef;

  /** primary **/
  --color-primary: #222428;
  --color-primary-rgb: 56, 128, 255;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 255, 255, 255;
  --color-primary-shade: #2a2a2a;
  --color-primary-tint: #363636;

  /** secondary **/
  --color-secondary: #3880ff;
  --color-secondary-rgb: 56, 128, 255;
  --color-secondary-contrast: #ffffff;
  --color-secondary-contrast-rgb: 255, 255, 255;
  --color-secondary-shade: #3171e0;
  --color-secondary-tint: #4c8dff;

  /** tertiary **/
  --color-tertiary: #7044ff;
  --color-tertiary-rgb: 112, 68, 255;
  --color-tertiary-contrast: #ffffff;
  --color-tertiary-contrast-rgb: 255, 255, 255;
  --color-tertiary-shade: #633ce0;
  --color-tertiary-tint: #7e57ff;

  /** success **/
  --color-success: #8ec252;
  --color-success-rgb: 142, 194, 82;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 255, 255, 255;
  --color-success-shade: #7dab48;
  --color-success-tint: #99c863;

  /** warning **/
  --color-warning: #ffce00;
  --color-warning-rgb: 255, 206, 0;
  --color-warning-contrast: #222428;
  --color-warning-contrast-rgb: 255, 255, 255;
  --color-warning-shade: #e0b500;
  --color-warning-tint: #ffd31a;

  /** danger **/
  --color-danger: #f04141;
  --color-danger-rgb: 245, 61, 61;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255, 255, 255;
  --color-danger-shade: #d33939;
  --color-danger-tint: #f25454;

  /** dark **/
  --color-dark: #222428;
  --color-dark-rgb: 34, 34, 34;
  --color-dark-contrast: #ffffff;
  --color-dark-contrast-rgb: 255, 255, 255;
  --color-dark-shade: #1e2023;
  --color-dark-tint: #383a3e;

  /** medium **/
  --color-medium: #989aa2;
  --color-medium-rgb: 152, 154, 162;
  --color-medium-contrast: #222428;
  --color-medium-contrast-rgb: 255, 255, 255;
  --color-medium-shade: #86888f;
  --color-medium-tint: #a2a4ab;

  /** light **/
  --color-light: #f4f5f8;
  --color-light-rgb: 244, 244, 244;
  --color-light-contrast: #000000;
  --color-light-contrast-rgb: 0, 0, 0;
  --color-light-shade: #d7d8da;
  --color-light-tint: #f5f6f9;

  --zebra-item-background: #f0f1f4;
  --link-color: #3880ff;
  --menu-item-selected-background-color: #8ec252;
  --menu-item-selected-color: white;

  --color-step-950: #1e1e1e;
  --color-step-900: #2a2a2a;
  --color-step-850: #363636;
  --color-step-800: #414141;
  --color-step-750: #4d4d4d;
  --color-step-700: #595959;
  --color-step-650: #656565;
  --color-step-600: #717171;
  --color-step-550: #7d7d7d;
  --color-step-500: #898989;
  --color-step-450: #949494;
  --color-step-400: #a0a0a0;
  --color-step-350: #acacac;
  --color-step-300: #b8b8b8;
  --color-step-250: #c4c4c4;
  --color-step-200: #d0d0d0;
  --color-step-150: #dbdbdb;
  --color-step-100: #e7e7e7;
  --color-step-50: #f3f3f3;
  --item-background: #fff;
  --toolbar-color: rgba(50, 50, 50, 0.1);
  --border-color: #e7e7e7;
  --reveal-gradient-left: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --reveal-gradient-right: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --reveal-gradient-top: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --reveal-gradient-bottom: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  --color-facebook: #3b5998;
  --color-facebook-rgb: 59, 89, 152;
  --color-facebook-contrast: #ffffff;
  --color-facebook-contrast-rgb: 255, 255, 255;
  --color-facebook-shade: #344e86;
  --color-facebook-tint: #4f6aa2;

  --color-gold: #ffd700;
  --color-gold-rgb: 255, 215, 0;
  --color-gold-contrast: #000000;
  --color-gold-contrast-rgb: 0, 0, 0;
  --color-gold-shade: #e0bd00;
  --color-gold-tint: #ffdb1a;
}

.magictime.puffIn {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-step-800);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rating-icon {
  height: 70px;
  width: 70px;
  user-select: none;
}

button {
  appearance: none;
  background-color: var(--color-secondary);
  border: solid 1px var(--color-secondary-tint);
  border-radius: 5px;
  box-shadow: none;
  color: var(--color-secondary-contrast);
  font-size: 18px;
  font-weight: 500;
  padding: 7px 40px;
  outline: none;
}

button:hover {
  background-color: var(--color-secondary-tint);
}

button:active {
  background-color: var(--color-secondary-shade);
}

button.disabled {
  border-color: var(--color-step-300);
  background-color: var(--color-step-350);
}

.survey {
  align-items: center;
  background-color: var(--color-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.survey-container {
  align-items: center;
  background-color: white;
  border-radius: 6px;
  border: solid 1px rgba(--color-light-tint);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
}

.survey-container button {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
}

.survey-container h1 {
  color: var(--color-step-700);
  margin: 0 0 40px;
  text-align: center;
}

.subtext {
  color: var(--color-step-700);
  font-size: 20px;
  padding: 0;
  margin: 0 0 20px;
  text-align: center;
}

.survey-container .error {
  color: var(--color-danger);
  font-weight: 500;
  margin: 0;
}

.back-button {
  align-items: center;
  color: var(--color-secondary);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-weight: 500;
  margin-bottom: 20px;
  visibility: hidden;
  user-select: none;
  width: 100%;
}

.back-button.visible {
  visibility: visible;
}

.back-button .chevron {
  flex: 0 0 0;
  margin-right: 12px;
  pointer-events: none;
}

.back-button label {
  flex: 1 1 auto;
  pointer-events: none;
}

.talk-bubble {
  width: 120px;
  height: 80px;
  background: var(--color-secondary);
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.talk-bubble:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 26px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 26px solid var(--color-secondary);
  border-bottom: 13px solid transparent;
}

.chevron {
  position: relative;
}

.chevron:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: 9px;
  background: var(--color-secondary);
  transform: skew(0deg, 40deg);
}

.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 3px;
  width: 9px;
  background: var(--color-secondary);
  transform: skew(0deg, -40deg);
}

.chevron.left {
  transform: rotate(-90deg);
}

.facebook-button {
  align-items: center;
  color: rgb(24, 119, 242);
  font-weight: 600;
  font-family: FacebookReader, NotoSans, sans-serif;
  display: flex;

  flex-direction: row;
  flex-wrap: nowrap;
  text-decoration: none;
}

.facebook-button:hover {
  filter: brightness(120%);
}

.facebook-button .facebook-logo {
  flex: 0 0 30px;
  margin-left: 10px;
  height: 30px;
  width: 30px;
}

.survey-container .facebook-button {
  margin-top: 20px;
  margin-bottom: 20px;
}

.four-oh-four {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  font-size: 30px;
}

.four-oh-four .go-back {
  color: var(--link-color);
  font-size: 18px;
  margin-top: 20px;
  text-decoration: underline;
}

@media screen and (max-width: 700px) {
  .rating-icon {
    height: 45px;
    width: 45px;
  }

  .survey-container h1 {
    font-size: 26px;
  }

  .survey-container .subtext {
    font-size: 18px;
  }

  .survey-container {
    padding: 20px;
    margin: 10px;
  }
}

.survey-container textarea {
  border: solid 1px var(--border-color);
  border-radius: 6px;
  font-size: 15px;
  height: 120px;
  padding: 10px;
  resize: none;
  width: 100%;
}
